
import {
  computed, defineComponent, toRefs, PropType,
} from 'vue';

import { IAtom, AtomType } from '@/domains/atom';
import AssignmentMulticontentPreview from './AssignmentMulticontentPreview.vue';
import AssignmentMultileveltestPreview from './AssignmentMultileveltestPreview.vue';
import AssignmentScormPreview from './AssignmentScormPreview.vue';
import AssignmentQuizPreview from './AssignmentQuizPreview.vue';
import AssignmentTrackPreview from './AssignmentTrackPreview.vue';
import AssignmentUnknownPreview from './AssignmentUnknownPreview.vue';

export default defineComponent({
  name: 'AssignmentAtomPreview',

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },

    atomType: {
      type: String as PropType<IAtom['atomName']>,
      required: true,
    },
  },

  setup(props) {
    const { atomType } = toRefs(props);

    const atomPreviewComponent = computed(() => {
      switch (atomType.value) {
        case AtomType.MULTICONTENT:
          return AssignmentMulticontentPreview;
        case AtomType.SCORM:
          return AssignmentScormPreview;
        case AtomType.MULTILEVELTEST:
          return AssignmentMultileveltestPreview;
        case AtomType.QUIZ:
          return AssignmentQuizPreview;
        case AtomType.TRACK:
          return AssignmentTrackPreview;
        case AtomType.UNKNOWN:
        default:
          return AssignmentUnknownPreview;
      }
    });

    return {
      atomPreviewComponent,
    };
  },
});
