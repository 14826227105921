
import {
  defineComponent, PropType, computed, toRefs,
} from 'vue';
import { useDayJs } from '@/plugins/dayjs/composables';
import { useI18n } from '@/plugins/vue-i18n';
import BaseChip from '@/components/ui/BaseChip';

export default defineComponent({
  name: 'DeadlineChip',

  components: {
    BaseChip,
  },

  props: {
    finishDate: {
      type: String as PropType<DateTimeISO8601>,
      default: null,
    },
  },

  setup(props) {
    const { finishDate } = toRefs(props);

    const { t } = useI18n();
    const dayjs = useDayJs();

    const isDeadlineFailed = computed<boolean>(() => {
      const now = dayjs();
      const deadline = dayjs(finishDate.value);
      return now > deadline;
    });

    const chipText = computed(() => {
      const deadline = dayjs(finishDate.value);
      const time = deadline.format('HH:mm');
      const fullDate = deadline.format('DD MMMM');

      if (isDeadlineFailed.value) {
        return t('DeadlineChip.failed');
      }

      if (deadline.isToday()) {
        return t('DeadlineChip.completeToday', { time });
      }

      if (deadline.isTomorrow()) {
        return t('DeadlineChip.completeTomorrow', { time });
      }

      return t('DeadlineChip.completeBefore', { date: fullDate });
    });

    const isSessionFailed = computed(() => {
      const today = dayjs();
      const deadline = dayjs(finishDate.value);

      return today.isAfter(deadline, 'seconds');
    });

    const isSessionExpires = computed(() => {
      const today = dayjs();
      const deadline = dayjs(finishDate.value);

      return deadline.diff(today, 'week') < 1;
    });

    const chip = computed(() => {
      if (isSessionFailed.value) {
        return {
          icon: '$exclamationCircle',
          type: 'new-danger',
          text: t('DeadlineChip.failed'),
        };
      }

      if (isSessionExpires.value) {
        return {
          icon: '$calendarSolid',
          type: 'new-danger',
          text: chipText.value,
        };
      }

      return {
        icon: '$calendarSolid',
        type: 'default',
        text: chipText.value,
      };
    });

    const isVisible = computed(() => {
      return finishDate.value !== null;
    });

    return {
      chip,
      isVisible,
    };
  },
});
