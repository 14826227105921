
import {
  ref, computed, defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { IBreadcrumb } from '@/domains/common';
import Names from '@/plugins/vue-router/routeNames';
import { useI18n } from '@/plugins/vue-i18n';

import { AtomPreview } from '@/components/AtomPreview';

import { useAssignment } from '@/domains/assignment/composables';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { AtomType } from '@/domains/atom';
import useCourseSession from '@/domains/course/composables/useCourseSession';
import { DeadlineChip } from '@/components/ui/DeadlineChip';
import { useDi } from '@/plugins';
import { BasePage } from '@/components/ui/BasePage';
import { useFeatureFlag } from '@/plugins/feature-flags';

export default defineComponent({
  name: 'AssignmentTrackPreview',

  components: {
    AtomPreview,
    DeadlineChip,
    BasePage,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { playerSessionId } = toRefs(props);

    const { t } = useI18n();
    const router = useRouter();
    const di = useDi();

    const isLoading = ref<boolean>(true);

    const isNewCourseAvailable = useFeatureFlag('atom_publication_course');

    const {
      isLoading: isAssignmentLoading,
      fetchAssignmentBySession: fetchAssignment,
      assignmentDeadline,
      hasDeadline,
      getDeadlineVisibility,
      isSelfAssignment,
      catalogueAtomVersionId,
      assignmentUserId,
      onUnassignHandler,
    } = useAssignment({ playerSessionId, atomType: AtomType.TRACK });

    const {
      // isLoading: isAtomLoading,
      fetchAtom,
      atom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const {
      trackSession,
      fetch: fetchSession,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
    } = useCourseSession({ trackSessionId: playerSessionId });

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-program-link',
      },
      {
        text: atom.value?.name ?? '',
        'data-test-label': 'program-name-link',
        disabled: true,
      },
    ]);

    onMounted(async () => {
      Promise.all([
        fetchSession(),
        fetchAssignment()
          .then(() => {
            if (!isNewCourseAvailable.value) {
              router.replace({
                name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_VIEW,
                params: {
                  assignmentId: String(assignmentUserId.value),
                  trackSessionId: String(playerSessionId.value),
                },
              });

              return Promise.reject(new Error(
                'Новое прохождение курса отключено (ff: "atom_publication_course") - перевожу на старое',
              ));
            }

            return Promise.resolve();
          })
          .then(fetchAtom),
      ])
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const onClickStartHandler = () => {
      return router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_STEP,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    const onClickContinueHandler = () => {
      return router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_STEP,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    const onClickRestartHandler = () => {
      console.log('Implement onClickRestartHandler method in the AssignmentTrackPreview component');
    };

    const onClickUnassignHandler = async ({ resolve, reject }: { resolve: () => void; reject: () => void }) => {
      try {
        await onUnassignHandler({
          paths: {
            assignmentUserId: assignmentUserId.value,
          },
        });

        resolve();

        await router.push({
          name: Names.R_APP_LEARNING_CATALOGUE_ATOM_PREVIEW,
          params: {
            catalogueAtomId: catalogueAtomVersionId.value,
            atomType: AtomType.TRACK,
          },
        });
      } catch (e: any) {
        reject();

        di.notify.error({
          title: t('AtomPreview.error.unassign'),
        });
      }
    };

    return {
      isLoading,
      atom,
      trackSession,
      assignmentDeadline,
      hasDeadline,
      getDeadlineVisibility,
      isAssignmentLoading,
      isSelfAssignment,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
      breadcrumbs,
      onClickUnassignHandler,
      onClickStartHandler,
      onClickContinueHandler,
      onClickRestartHandler,
    };
  },
});
