
import {
  ref, computed, defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { IBreadcrumb } from '@/domains/common';
import { useMultileveltestPlayer } from '@/domains/multileveltest/composables/useMultileveltestPlayer';
import { Names } from '@/plugins/vue-router';
import { useI18n } from '@/plugins/vue-i18n';
import { useDi } from '@/plugins';

import { AtomPreview } from '@/components/AtomPreview';

import { useAssignment } from '@/domains/assignment/composables';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { AtomType } from '@/domains/atom';
import { DeadlineChip } from '@/components/ui/DeadlineChip';
import { BasePage } from '@/components/ui/BasePage';

export default defineComponent({
  name: 'AssignmentMultileveltestPreview',

  components: {
    AtomPreview,
    DeadlineChip,
    BasePage,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    const { playerSessionId } = toRefs(props);

    const { t } = useI18n();
    const router = useRouter();
    const di = useDi();

    const isLoading = ref<boolean>(true);

    const {
      isLoading: isAssignmentLoading,
      fetchAssignmentBySession: fetchAssignment,
      assignment,
      assignmentDeadline,
      hasDeadline,
      getDeadlineVisibility,
      isSelfAssignment,
      catalogueAtomVersionId,
      assignmentUserId,
      onUnassignHandler,
    } = useAssignment({ playerSessionId, atomType: AtomType.MULTILEVELTEST });

    const {
      isLoading: isAtomLoading,
      fetchAtom,
      atom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const {
      isLoading: isSessionLoading,
      content: session,
      fetchSession,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
    } = useMultileveltestPlayer({ playerSessionId }, context);

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-program-link',
      },
      {
        text: atom.value?.name ?? '',
        'data-test-label': 'program-name-link',
        disabled: true,
      },
    ]);

    const onClickStartOrContinueButtonHandler = async () => {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_INTRO,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    const onClickResultsButtonHandler = async () => {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_RESULT,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    onMounted(async () => {
      Promise.all([
        fetchSession(),
        fetchAssignment()
          .then(fetchAtom),
      ])
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const onClickUnassignHandler = async ({ resolve, reject }: { resolve: () => void; reject: () => void }) => {
      try {
        await onUnassignHandler({
          paths: {
            assignmentUserId: assignmentUserId.value,
          },
        });

        resolve();

        await router.push({
          name: Names.R_APP_LEARNING_CATALOGUE_ATOM_PREVIEW,
          params: {
            catalogueAtomId: catalogueAtomVersionId.value,
            atomType: AtomType.MULTILEVELTEST,
          },
        });
      } catch (e: any) {
        reject();

        di.notify.error({
          title: t('AtomPreview.error.unassign'),
        });
      }
    };

    return {
      isLoading,
      isSessionLoading,
      isAtomLoading,
      session,
      atom,
      assignment,
      assignmentDeadline,
      hasDeadline,
      getDeadlineVisibility,
      isAssignmentLoading,
      isSelfAssignment,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
      breadcrumbs,
      onClickUnassignHandler,
      onClickStartOrContinueButtonHandler,
      onClickResultsButtonHandler,
    };
  },
});
