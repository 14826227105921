
import {
  ref, computed, defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { IBreadcrumb, PlayerSessionStatus } from '@/domains/common';
import { Names } from '@/plugins/vue-router';
import { useI18n } from '@/plugins/vue-i18n';

import { AtomPreview } from '@/components/AtomPreview';

import { AtomType } from '@/domains/atom';
import { useAssignment } from '@/domains/assignment/composables';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { DeadlineChip } from '@/components/ui/DeadlineChip';
import { useQuizSession } from '@/domains/quiz/composables/useQuizSession';
import { useDi } from '@/plugins';
import { getRunTestAgainButtonTimePart } from '@/helpers/time/blockingRunQuizButton';
import { BasePage } from '@/components/ui/BasePage';

export default defineComponent({
  name: 'AssignmentQuizPreview',

  components: {
    AtomPreview,
    DeadlineChip,
    BasePage,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { playerSessionId } = toRefs(props);

    const { t } = useI18n();
    const router = useRouter();
    const di = useDi();

    const isLoading = ref<boolean>(true);
    const isError = ref<boolean>(false);

    const {
      isLoading: isAssignmentLoading,
      fetchAssignmentBySession: fetchAssignment,
      assignment,
      assignmentDeadline,
      hasDeadline,
      getDeadlineVisibility,
      isSelfAssignment,
      catalogueAtomVersionId,
      assignmentUserId,
      onUnassignHandler,
    } = useAssignment({ playerSessionId, atomType: AtomType.QUIZ });

    const {
      // isLoading: isAtomLoading,
      fetchAtom,
      atom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const {
      // isLoading: isSessionLoading,
      session,
      fetchSession,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
      timeBetweenAttempts,
      showPassAgainButton,
    } = useQuizSession({ playerSessionId });

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-program-link',
      },
      {
        text: atom.value?.name ?? '',
        'data-test-label': 'program-name-link',
        disabled: true,
      },
    ]);

    const buttonText = computed<string>(() => {
      const str = getRunTestAgainButtonTimePart(timeBetweenAttempts.value);

      switch (session.value!.playerSessionStatus) {
        case PlayerSessionStatus.ACTIVE:
          return t('QuizIntro.buttonText.continue');
        case PlayerSessionStatus.NEW:
        default:
          return str.length
            ? t('QuizIntro.buttonText.start.time', { time: str })
            : t('QuizIntro.buttonText.start.withoutTime');
      }
    });

    const onClickStartOrContinueButtonHandler = async () => {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_INTRO,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    const onClickResultsButtonHandler = async () => {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_RESULT,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    onMounted(async () => {
      Promise.all([fetchSession(), fetchAssignment().then(fetchAtom)])
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const onClickUnassignHandler = async ({ resolve, reject }: { resolve: () => void; reject: () => void }) => {
      try {
        await onUnassignHandler({ paths: { assignmentUserId: assignmentUserId.value } });

        resolve();

        await router.push({
          name: Names.R_APP_LEARNING_CATALOGUE_ATOM_PREVIEW,
          params: {
            catalogueAtomId: catalogueAtomVersionId.value,
            atomType: AtomType.QUIZ,
          },
        });
      } catch (e: any) {
        reject();

        di.notify.error({
          title: t('AtomPreview.error.unassign'),
        });
      }
    };

    return {
      isLoading,
      isError,
      session,
      atom,
      assignment,
      assignmentDeadline,
      hasDeadline,
      getDeadlineVisibility,
      isAssignmentLoading,
      isSelfAssignment,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
      breadcrumbs,
      onClickStartOrContinueButtonHandler,
      onClickResultsButtonHandler,
      onClickUnassignHandler,
      buttonText,
      showPassAgainButton,
    };
  },
});
