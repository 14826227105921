
import {
  defineComponent,
} from 'vue';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import UpButton from '@/components/ui/UpButton.vue';
import { useI18n } from '@/plugins/vue-i18n';

export default defineComponent({
  name: 'AssignmentUnknownPreview',

  components: {
    TTBackButton,
    UpButton,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
